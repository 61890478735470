@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* fix Mui Table Pagination component margins issue by bootstrap */
.MuiTablePagination-root p {
  margin: 0px;
}
/* remove autofill colors on chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000000 !important;
}
.model-head {
  background-color: #00ab55;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  color: white;
  padding: 0.8rem 0.7rem 0rem 0.7rem;
}

/* .edit-user-plan-cards .css-19r6kue-MuiContainer-root{
  overflow: hidden;
} */